import './App.css';
import React, { useState } from 'react';
import Order from './components/order_form'
import CancelOrder from './components/cancel_order'
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink
} from "react-router-dom";
import { URL_EXTENSION,SERVER_URI, } from './utility/config';
import axios from 'axios';
import ErrorPage from './components/Error_page';
import usePWAInstall from "use-pwa-install";

function App() {
  const {isInstalled, install} = usePWAInstall()

  const NavBar = () => {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    return <header className="App-header">
      <ul className={click ? "nav-options active" : "nav-options"}>
        <li className="option" onClick={closeMobileMenu}>
          <NavLink exact activeClassName="active" to={`${process.env.PUBLIC_URL}/`}>Tilaa taksi</NavLink>
        </li>
        <li className="option" onClick={closeMobileMenu}>
          <NavLink activeClassName="active" to={`${process.env.PUBLIC_URL}/orders`}>Tilaukset</NavLink>
        </li>
      </ul>
      <div className="mobile-menu" onClick={handleClick}>
        {click ? (
          <CloseIcon style=
            {{
              fontSize: 29,

            }}
            className="menu-icon" />
        ) : (
          <MenuIcon style={{

            fontSize: 29,
          }}
            className="menu-icon" />
        )}
      </div>
    </header>
  }

  const StopListRefresh = () => {
    let i;
    for (i = 0; i < 10000; i++) {
      window.clearInterval(i);
    }
  }

  return (
    <Router basename={URL_EXTENSION}>
      <div className="App">
        <NavBar />
        {!isInstalled && <button className="gradient_Button" onClick={install}>Asenna sovellus</button> }
        <Switch>
          <Route exact path={`${process.env.PUBLIC_URL}/orders`}>
            <div className="orderForms">
              <CancelOrder StopListRefresh={StopListRefresh} />
            </div>
          </Route>
          <Route exact path={`${process.env.PUBLIC_URL}/error`}>
              <ErrorPage StopListRefresh={StopListRefresh} />
          </Route>
          <Route exact path={`${process.env.PUBLIC_URL}/`}>
            <div className="orderForms">
              <Order />
            </div>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
