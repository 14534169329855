let SERVER_URI;
let URL_EXTENSION;

if (process.env.NODE_ENV === 'production') {
  SERVER_URI = "./api";
  URL_EXTENSION = "/";
} else {
  SERVER_URI = "http://localhost:3000/api";
  URL_EXTENSION = "/";
}
// Month needs to be in English
let UpdateDate = "October 13, 2020 11:13"
let TERMS_OF_USE_UPDATE_DATE = new Date(UpdateDate)

export {
  SERVER_URI,
  URL_EXTENSION,
  TERMS_OF_USE_UPDATE_DATE
};