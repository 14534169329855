import React, {useEffect, useState} from 'react';
import '../App.css';
import axios from 'axios';
import moment from 'moment'
import {
    SERVER_URI,
    TERMS_OF_USE_UPDATE_DATE
} from "../utility/config";

function OrderConfirmedMessage(props) {
    function refreshPage() {
        window.location.href = `${process.env.PUBLIC_URL}/`;
    }

    return (
        <div className="order">
            <h2 className="title">Tilaus vahvistettu</h2>
            <p>Tilausnumeronne on : {props.orderId}</p>
            <button className="gradient_Button" onClick={refreshPage}>Palaa takaisin</button>
        </div>
    );
}

function ErrorPopUp(props) {
    const [translatedError, setTranslatedError] = useState('');
    useEffect(() => {

        const translateData = () => {
            var str = props.errorMessage;
            var matches = str.match(/(\d+)/);
            if (matches !== null) {
                let time = matches[0]
                console.log("testi", props.errorMessage)
                if (props.errorMessage === "Order not allowed for this time period." || props.errorMessage === "A driver is not available for this time period.") {
                    setTranslatedError("Valitettavasti valitsemallenne ajankohdalle ei voida tehdä tilausta. Kokeilkaa toista ajankohtaa tai ottakaa yhteys asiakaspalveluun.")
                } else if (props.errorMessage === "Error, given discount time not available." || props.errorMessage === "Malformed request, timeTolerance-field is of incorrect type, number expected.") {
                    setTranslatedError("Virhe tilauksessa! Olkaa hyvä ja tarkistakaa tilauksen tiedot. ")
                } else if (props.errorMessage === "Malformed request, timed orders cannot be placed less than", time, " hours from the present.") {
                    setTranslatedError("Noin- ja Aikatilauksien määräaika voi olla aikaisintaan " + time + "h nykyhetkestä. Olkaa hyvä ja valitkaa toinen määräaika. ")
                }
            } else {
                if (props.errorMessage === "A driver is not available for this time period." || props.errorMessage === "Order not allowed for this time period.") {
                    setTranslatedError("Valitettavasti valitsemallenne ajankohdalle ei voida tehdä tilausta. Kokeilkaa toista ajankohtaa tai ottakaa yhteys asiakaspalveluun.")
                } else if (props.errorMessage === "Orderer phone number found in blacklist.") {
                    setTranslatedError("Virhe tilauksessa! Syötetty puhelinnumero on estolistalla.")
                } else if (props.errorMessage === "Couldn't connect to location api.") {
                    setTranslatedError("Virhe paikannettaessa.")
                } else if (props.errorMessage === "Orderer isn't whitelisted and has one or more ongoing orders already.") {
                    setTranslatedError("Teillä on jo aktiivinen tilaus/tilauksia.")
                }

                else {
                    setTranslatedError("Virhe tilauksessa! Olkaa hyvä ja tarkistakaa tilauksen tiedot. ")
                }
            }
        }
        translateData();
        // DON'T DELETE THE COMMENT BELOW!!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className="order">
            <div className="popup-box">
                <div className="box">
                    <span className="close-icon" onClick={props.handleClose}>x</span>
                    <div>
                        <h2 className="order_title">Virhe!</h2>
                        {translatedError}
                    </div>
                </div>
            </div>

        </div>

    );
}

function Order() {
    const [newAgreementNeeded, setNewAgreementNeeded] = useState(false);
    const [show, setShow] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [popupInfo, setPopupInfo] = useState('');
    const [pickUpM, setPickUpM] = useState(false);
    const [targetM, setTargetM] = useState(false);
    const [pickUpType, setPickUpType] = useState('');
    const [targetType, setTargetType] = useState('');
    const [passenger, setPassenger] = useState(true);
    const [selectedButton, setSelectedButton] = useState('');
    const [orderId, setOrderId] = useState('');
    const [orderType, setOrderType] = useState('immediate');
    const [errorDate, setErrorDate] = useState(null);
    const [agreeError, setAgreeError] = useState(false);
    const [discounts, setDiscounts] = useState([]);
    const [isChosen, setIsChosen] = useState(false);
    const [userData, setUserData] = useState({
        ordererPhoneNumber: '',
        ordererCallSign: '',
        passengerPhoneNumber: '',
        passengerCallSign: '',
        orderType: '',
        pickUpLocation: '',
        pickUpMunicipality: '',
        targetLocation: '',
        targetMunicipality: '',
        passengerCount: '1',
        pickUpTime: '',
        timeTolerance: "0",
        acceptedTermsOfUse: false
    })
    let today = new Date();

    const getLocation = async () => {
        let dataToServer
        const location = await navigator.geolocation
        await location.getCurrentPosition(async (e) => {
            dataToServer = {
                latitude: e.coords.latitude,
                longitude: e.coords.longitude
            }
            try {
                const result = await axios.post(`${SERVER_URI}/get_address/`, dataToServer)
                let city = result.data.city
                setUserData({...userData, pickUpMunicipality: result.data.city, pickUpLocation: result.data.address})
                setPickUpType(city)
                /* setPickUpM(true) */
            } catch (err) {
                let errorMessage = err.response.data.error
                setPopupInfo(errorMessage)
                setIsOpen(!isOpen)
                console.log(err)
            }
        }, (err) => {
            console.log("ERROR: ", err)
        }, {timeout: 5000})
    }

    useEffect(() => {
        const fetchDiscounts = async (index) => {

            let discountsFromServer;
            let lastUserOrder = JSON.parse(window.localStorage.getItem('latestUserOrder'));
            try {
                discountsFromServer = (await axios.get(`${SERVER_URI}/list_discount_rates/`))
                setDiscounts(discountsFromServer.data)
            } catch (e) {
                console.log("Error in getting discounts", e)
            }

            function formatDate(string) {
                var options = {
                    hour: 'numeric',
                    minute: 'numeric'
                };
                return new Date(string).toLocaleString([], options);
            }

            let a = ''
            let b = ''
            let newArray = ["Valitse alennus"]
            let newDiscountTime = []
            for (let i = 0; i < discountsFromServer.data.length; i++) {
                if (lastUserOrder == null) {

                } else {
                    let NewDateObj = new Date(lastUserOrder.pickUpTime)
                    let NewDateObj2 = new Date(lastUserOrder.pickUpTime)

                    var discountTime = discountsFromServer.data[i].time
                    let OptionA = NewDateObj.setMinutes(NewDateObj.getMinutes() + discountTime)
                    a = formatDate(OptionA)
                    let OptionB = NewDateObj2.setMinutes(NewDateObj2.getMinutes() - discountTime)
                    b = formatDate(OptionB)
                    newDiscountTime.push(discountTime)
                    newArray.push(b + "-" + a + " , Alennus: -" + discountsFromServer.data[i].discount + "%")

                    setTimeout(function () {
                        var select = document.getElementById("timeTolerance");
                        if (select != null) {
                            if (select.options.length) {
                                // has children
                                select.options.length = 0
                                for (index in newArray) {
                                    select.options[select.options.length] = new Option(newArray[index], newDiscountTime[index - 1]);
                                }
                            } else {
                                // empty
                                for (index in newArray) {
                                    select.options[select.options.length] = new Option(newArray[index], newDiscountTime[index - 1]);
                                }
                            }
                        }

                    }, 10);
                }
            }

        }
        if (isChosen === false) {
            setInterval(function () {
                fetchDiscounts();
            }, 1500);
        } else {
            let i;
            for (i = 0; i < 10000; i++) {
                window.clearInterval(i);
            }
        }
        // DON'T DELETE THE COMMENT BELOW!!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChosen])

    useEffect(() => {

        const fetchData = async (index) => {
            let discountsFromServer;
            try {
                discountsFromServer = (await axios.get(`${SERVER_URI}/list_discount_rates/`))
                // orderLimit = (await axios.get(`${SERVER_URI}/get_timed_order_limit/`))
                // setOrderLimit(orderLimit.data)
                setDiscounts(discountsFromServer.data)
            } catch (e) {
                console.log("Error in getting discounts", e)
            }

            let lastUserOrder = JSON.parse(window.localStorage.getItem('latestUserOrder'));
            let TermsOfUseAgreed = new Date(window.localStorage.getItem('TermsOfUseAgreed'));
            if (TERMS_OF_USE_UPDATE_DATE > TermsOfUseAgreed) {
                setNewAgreementNeeded(true)
            } else {
                userData.acceptedTermsOfUse = true
            }
            if (lastUserOrder !== null) {
                const rightTime = moment(new Date(lastUserOrder.pickUpTime)).format("YYYY-MM-DDTkk:mm")
                let newOrder = {
                    ordererPhoneNumber: lastUserOrder.ordererPhoneNumber,
                    ordererCallSign: lastUserOrder.ordererCallSign,
                    passengerPhoneNumber: lastUserOrder.ordererPhoneNumber,
                    passengerCallSign: lastUserOrder.ordererCallSign,
                    orderType: lastUserOrder.orderType,
                    pickUpLocation: lastUserOrder.pickUpLocation,
                    pickUpMunicipality: lastUserOrder.pickUpMunicipality,
                    targetLocation: lastUserOrder.targetLocation,
                    targetMunicipality: lastUserOrder.targetMunicipality,
                    passengerCount: lastUserOrder.passengerCount,
                    pickUpTime: rightTime,
                    timeTolerance: 0,
                    acceptedTermsOfUse: false
                }

                if (TERMS_OF_USE_UPDATE_DATE > TermsOfUseAgreed) {
                    setNewAgreementNeeded(true)
                } else {
                    newOrder.acceptedTermsOfUse = true
                }
                // check if  pickUp Municipality is the option other
                if (newOrder.pickUpMunicipality === "Tampere" || newOrder.pickUpMunicipality === "Pirkkala" || newOrder.pickUpMunicipality === "Kangasala" || newOrder.pickUpMunicipality === "Nokia" || newOrder.pickUpMunicipality === "Ylöjärvi" || newOrder.pickUpMunicipality === "Lempäälä") {
                    setPickUpM(false)
                    setPickUpType(newOrder.pickUpMunicipality)
                } else {
                    setPickUpM(true)
                    setPickUpType("Other")
                }
                // check if target Municipality is the option other
                if (newOrder.targetMunicipality === "Tampere" || newOrder.targetMunicipality === "Pirkkala" || newOrder.targetMunicipality === "Kangasala" || newOrder.targetMunicipality === "Nokia" || newOrder.targetMunicipality === "Ylöjärvi" || newOrder.targetMunicipality === "Lempäälä") {
                    setTargetM(false)
                    setTargetType(newOrder.targetMunicipality)
                } else {
                    setTargetM(true)
                    setTargetType("Other")
                }

                // set order defaults and buttons
                setPassenger(true)
                setUserData(newOrder)
                setSelectedButton(newOrder.orderType)
                setOrderType(newOrder.orderType)
            }
        }
        fetchData();
        // DON'T DELETE THE COMMENT BELOW!!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // onChange functions
    const onChange = (e) => {
        setUserData({...userData, [e.target.name]: e.target.value})
    }

    const onChangeDiscount = (e) => {
        setUserData({...userData, [e.target.name]: e.target.value})
        setIsChosen(true)
    }
    const switchAddress = (e) => {
        if (pickUpM === true) {
            setTargetType("Other")
            setTargetM(true)
        } else {
            setTargetM(false)
            setTargetType(userData.pickUpMunicipality)
        }
        if (targetM === true) {
            setPickUpType("Other")
            setPickUpM(true)
        } else {
            setPickUpM(false)
            setPickUpType(userData.targetMunicipality)
        }

        setUserData({
            ...userData,
            pickUpLocation: userData.targetLocation,
            pickUpMunicipality: userData.targetMunicipality,
            targetLocation: userData.pickUpLocation,
            targetMunicipality: userData.pickUpMunicipality
        })
    }

    function formatDate(string) {
        var options = {
            hour: 'numeric',
            minute: 'numeric'
        };
        return new Date(string).toLocaleString([], options);
    }

    const onChangeDate = (e, today, index) => {
        setIsChosen(true)
        const value = e.target.value
        let valueDate = new Date(value)
        if (valueDate < today) {
            setErrorDate("Et voi valita mennyttä aikaa, Valitse toinen aika");
        } else {
            setErrorDate(null);
            setUserData({...userData, pickUpTime: value})
            //Set options
            let a = ''
            let b = ''
            let newArray = ["Valitse alennus"]
            let newDiscountTime = []
            for (let i = 0; i < discounts.length; i++) {
                let NewDateObj = new Date(valueDate)
                let NewDateObj2 = new Date(valueDate)
                var discountTime = discounts[i].time
                let OptionA = NewDateObj.setMinutes(NewDateObj.getMinutes() + discountTime)
                a = formatDate(OptionA)
                let OptionB = NewDateObj2.setMinutes(NewDateObj2.getMinutes() - discountTime)
                b = formatDate(OptionB)
                newDiscountTime.push(discountTime)
                newArray.push(b + "-" + a + " , Alennus: -" + discounts[i].discount + "%")

                var select = document.getElementById("timeTolerance");
                if (select.options.length) {
                    // has children
                    select.options.length = 0
                    for (index in newArray) {
                        select.options[select.options.length] = new Option(newArray[index], newDiscountTime[index - 1]);
                    }
                } else {
                    // empty
                    for (index in newArray) {
                        select.options[select.options.length] = new Option(newArray[index], newDiscountTime[index - 1]);
                    }
                }
            }
        }
    }

    const onChangePickUpM = (e) => {
        const Value = e.target.value
        setPickUpType(Value)
        if (Value === "Other") {
            setPickUpM(true)
            setUserData({...userData, [e.target.name]: ''})
        } else {
            setPickUpM(false)
            setUserData({...userData, [e.target.name]: Value})
        }
    }
    const onChangeTargetM = (e) => {
        const Value = e.target.value
        setTargetType(Value)
        if (Value === "Other") {
            setTargetM(true)
            setUserData(({...userData, [e.target.name]: ''}))
        } else {
            setTargetM(false)
            setUserData({...userData, [e.target.name]: Value})
        }
    }
    const YesPassenger = (e) => {
        if (passenger === false) {
            setPassenger(true)
            setUserData({
                ...userData,
                passengerPhoneNumber: userData.ordererPhoneNumber,
                passengerCallSign: userData.ordererCallSign
            })
        }
    }
    const NoPassenger = (e) => {
        if (passenger === true) {
            setPassenger(false)
            setUserData({...userData, passengerPhoneNumber: '', passengerCallSign: ''})
        }
    }
    const onChangeOrdererName = (e) => {
        let name = e.target.value
        if (passenger === true) {
            setUserData({...userData, ordererCallSign: name, passengerCallSign: name})
        } else {
            setUserData({...userData, ordererCallSign: name})
        }
    }
    const onChangeOrdererPhone = (e) => {
        let phone = e.target.value
        if (passenger === true) {
            setUserData({...userData, ordererPhoneNumber: phone, passengerPhoneNumber: phone})
        } else {
            setUserData({...userData, ordererPhoneNumber: phone})
        }
    }

    const acceptTermsOfUse = (e) => {
        if (userData.acceptedTermsOfUse === false) {
            setUserData({...userData, acceptedTermsOfUse: true})
        } else {
            setUserData({...userData, acceptedTermsOfUse: false})
        }

        if (agreeError === true) {
            setUserData({...userData, acceptedTermsOfUse: false})
            setAgreeError(false)
        }
    }

    const ChangeOrderType = (e) => {
        let type = e.target.value
        if (type === 'immediate') {
            setUserData({...userData, timeTolerance: 0, orderType: type})
        } else {
            setUserData({...userData, orderType: type})
        }
        setOrderType(type)
        setSelectedButton(type)

    }

    // Submit
    const setpickupTime = () => {
        const todayTime = moment(new Date()).format("YYYY-MM-DDTkk:mm")
        if (userData.orderType === 'immediate') {
            setUserData({...userData, pickUpTime: todayTime})
        }
    }
    const togglePopup = (e) => {
        setIsOpen(!isOpen);
    }

    const doSubmit = async (e) => {
        e.preventDefault()
        let todayDate = new Date()
        if (userData.acceptedTermsOfUse === false) {
            setAgreeError(true)
        } else {
            const dataToServer = {
                ...userData,
                pickUpTime: new Date(Date.parse(userData.pickUpTime)).toString(),
                passengerCount: Number(userData.passengerCount),
                timeTolerance: Number(userData.timeTolerance)
            }
            // remove passenger info from the info going to localStorage
            const {passengerCallSign, passengerPhoneNumber, ...dataToLocalstorage} = dataToServer;

            try {
                let order = null
                setIsChosen(false)
                order = await axios.post(`${SERVER_URI}/order_ride`, dataToServer)
                setOrderId(order.data.orderId)
                setShow(!show);

                window.localStorage.setItem('latestUserOrder', JSON.stringify(dataToLocalstorage));
                window.localStorage.setItem('TermsOfUseAgreed', todayDate);
            } catch (error) {
                let errorMessage = error.response.data.error
                setPopupInfo(errorMessage)
                setIsOpen(!isOpen)
            }
        }
    }

    return (
        <div className="order">
            {!show && (
                <div>
                    <h2 className="title">Tilaa taksi</h2>
                    {userData.orderType === '' ?
                        <div className="OrderType_buttons">
                            <h3>Valitse tilaustyyppi</h3>
                            <button
                                className={selectedButton === 'immediate' ? "selected_type_button" : "gradient_Button"}
                                value="immediate" onClick={(e) => ChangeOrderType(e)}>Hetitilaus
                            </button>
                            <button
                                className={selectedButton === 'approximate' ? "selected_type_button" : "gradient_Button"}
                                value="approximate" onClick={(e) => ChangeOrderType(e)}>Nointilaus
                            </button>
                            <button
                                className={selectedButton === 'timed_reservation' ? "selected_type_button" : "gradient_Button"}
                                value="timed_reservation" onClick={(e) => ChangeOrderType(e)}>Aikatilaus
                            </button>
                        </div>
                        :
                        <div className="OrderType_buttons">
                            <button
                                className={selectedButton === 'immediate' ? "selected_type_button" : "gradient_Button"}
                                value="immediate" onClick={(e) => ChangeOrderType(e)}>Hetitilaus
                            </button>
                            <button
                                className={selectedButton === 'approximate' ? "selected_type_button" : "gradient_Button"}
                                value="approximate" onClick={(e) => ChangeOrderType(e)}>Nointilaus
                            </button>
                            <button
                                className={selectedButton === 'timed_reservation' ? "selected_type_button" : "gradient_Button"}
                                value="timed_reservation" onClick={(e) => ChangeOrderType(e)}>Aikatilaus
                            </button>

                            <form className="order_form" data-testid="my-form" onSubmit={e => doSubmit(e)}>
                                <label htmlFor="ordererCallSign">Nimimerkki:</label>
                                <input type="text" id="ordererCallSign" name="ordererCallSign"
                                       onChange={onChangeOrdererName} placeholder="Nimimerkki"
                                       value={userData.ordererCallSign} required></input><span
                                className="validity"></span> <br className="desktop-break"/>

                                <label htmlFor="ordererPhoneNumber">Puhelinnumero:</label>
                                <input type="tel" id="ordererPhoneNumber" name="ordererPhoneNumber"
                                       pattern="[0-9]{7,15}" placeholder="0101234567"
                                       onChange={onChangeOrdererPhone} value={userData.ordererPhoneNumber}
                                       required></input><span className="validity"></span><br
                                className="desktop-break"/>

                                <label htmlFor="IsPassenger">Tilaatko taksin itsellesi?</label>
                                <div className="checkboxes">
                                    <input type="radio" id="isPassenger" defaultChecked={passenger === true}
                                           name="isPassenger" onClick={YesPassenger}></input>
                                    <label htmlFor="YesPassenger">Kyllä</label>
                                    <input type="radio" id="isPassenger" name="isPassenger"
                                           defaultChecked={passenger === false} onClick={NoPassenger}></input>
                                    <label htmlFor="NoPassenger">En</label>
                                </div>

                                <div className={passenger === true ? "isPassenger" : "notPassenger"}>
                                    <label htmlFor="passengerCallSign">Matkustajan nimimerkki:</label>
                                    <input type="text" id="passengerCallSign" name="passengerCallSign"
                                           onChange={onChange} placeholder="Nimimerkki"
                                           value={userData.passengerCallSign} required></input><span
                                    className="validity"></span><br className="desktop-break"/>
                                    <label htmlFor="passengerPhoneNumber">Matkustajan puhelinnumero:</label>
                                    <input type="tel" id="passengerPhoneNumber" name="passengerPhoneNumber"
                                           pattern="[0-9]{7,15}" placeholder="0101234567"
                                           onChange={onChange} value={userData.passengerPhoneNumber}
                                           required></input><span className="validity"></span><br
                                    className="desktop-break"/>
                                </div>

                                <div className="addressDiv">
                                    <button type="button" onClick={switchAddress} className="material-icons">swap_vert
                                    </button>
                                    <div>
                                        <label htmlFor="pickUpMunicipality">Nouto-osoite:</label>
                                        <select name="pickUpMunicipality" id="pickUpMunicipality" value={pickUpType}
                                                onChange={onChangePickUpM} required>
                                            <option value="" hidden> Valitse Kaupunki</option>
                                            <option value="Tampere">Tampere</option>
                                            <option value="Pirkkala">Pirkkala</option>
                                            <option value="Kangasala">Kangasala</option>
                                            <option value="Nokia">Nokia</option>
                                            <option value="Ylöjärvi">Ylöjärvi</option>
                                            <option value="Lempäälä">Lempäälä</option>
                                            <option value="Other">Muu, mikä?</option>
                                        </select>
                                        <input type="text" id="pickUpMunicipality" name="pickUpMunicipality"
                                               placeholder="Kaupunki"
                                               className={pickUpM ? "Municipality_Active" : "Municipality_off"}
                                               onChange={onChange} value={userData.pickUpMunicipality}></input>
                                        <input type="text" id="pickUpLocation" name="pickUpLocation"
                                               placeholder="Nouto-osoite" onChange={onChange}
                                               value={userData.pickUpLocation} required></input>
                                        <button type="button" onClick={getLocation}>Hae sijainti</button>
                                        <span className="validity"></span><br className="desktop-break"/>
                                        <label htmlFor="targetLocation">Kohde-osoite:</label>
                                        <select name="targetMunicipality" id="targetMunicipality"
                                                onChange={onChangeTargetM} value={targetType}
                                                required={orderType === "approximate" || orderType === "timed_reservation"}>
                                            <option value="" hidden> Valitse Kaupunki</option>
                                            <option value="Tampere">Tampere</option>
                                            <option value="Pirkkala">Pirkkala</option>
                                            <option value="Kangasala">Kangasala</option>
                                            <option value="Nokia">Nokia</option>
                                            <option value="Ylöjärvi">Ylöjärvi</option>
                                            <option value="Lempäälä">Lempäälä</option>
                                            <option value="Other">Muu, mikä?</option>
                                        </select>
                                        <input type="text" id="targetMunicipality" name="targetMunicipality"
                                               placeholder="Kaupunki"
                                               className={targetM ? "Municipality_Active" : "Municipality_off"}
                                               onChange={onChange} value={userData.targetMunicipality}></input>
                                        <input type="text" id="targetLocation" name="targetLocation"
                                               placeholder="Kohde-osoite" onChange={onChange}
                                               value={userData.targetLocation}
                                               required={orderType === "approximate" || orderType === "timed_reservation"}></input><span
                                        className="validity"/><br className="desktop-break"/>
                                    </div>
                                </div>

                                <label htmlFor="passengerCount">Matkustajien määrä:</label>
                                <input type="number" id="passengerCount" name="passengerCount" min="1"
                                       value={userData.passengerCount} onChange={onChange} required></input><span
                                className="validity"></span> <br className="desktop-break"/>


                                <div
                                    className={orderType === 'approximate' || orderType === 'timed_reservation' ? "timeOrder" : "nowOrder"}>
                                    <label htmlFor="pickUpTime">Nouto-ajankohta ( Päivä ja aika):</label>
                                    <input type="datetime-local" id="pickUpTime" name="pickUpTime"
                                           onChange={e => onChangeDate(e, today)} min={today}
                                           value={userData.pickUpTime} required></input><span
                                    className="validity"></span><br className="desktop-break"/>
                                    {errorDate && (
                                        <label htmlFor="message" className="errorMessage">
                                            {errorDate}
                                        </label>
                                    )}
                                </div>
                                <div className={orderType === 'approximate' ? "timeOrder" : "nowOrder"}>
                                    <label htmlFor="timeTolerance">Nouto-ajan vaihteluväli:</label>
                                    <select name="timeTolerance" id="timeTolerance" value={userData.timeTolerance}
                                            onChange={onChangeDiscount} disabled={userData.pickUpTime === ''}>
                                    </select> {/*<span className="validity"></span><br className="desktop-break" /> */}
                                </div>
                                {newAgreementNeeded === true ? <div>
                                    <label htmlFor="acceptedTermsOfUse"> Hyväksyn <a id="TermsOfUseLink"
                                                                                     href="https://lomamuutto.fi/nointaksi/kayttoehdot">Käyttöehdot</a></label>
                                    <input type="checkbox" id="acceptedTermsOfUse" name="acceptedTermsOfUse"
                                           onChange={acceptTermsOfUse}></input><br/>
                                    {agreeError === true ? <div><label id="agree_chk_error">
                                        Ei voi edetä ennen kuin Käyttöehdot on hyväksytty!
                                    </label><br/></div> : ""}</div> : ""
                                }

                                <input type="submit" className="gradient_Button" onClick={setpickupTime} value="Tilaa"/>

                            </form>
                        </div>
                    }
                </div>
            )}
            {isOpen && <ErrorPopUp
                handleClose={togglePopup}
                errorMessage={popupInfo}
            />}
            {show && <OrderConfirmedMessage orderId={orderId}/>}
        </div>

    );
}

export default Order;