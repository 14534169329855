import React from 'react';
import '../App.css';
import axios from 'axios';
import {
    SERVER_URI
} from "../utility/config";


function ErrorPage(props) {
    
    props.StopListRefresh();

    return (
        <div className="error-display">
            <div>
                <h2 className="error_title">ERROR!</h2>
                <p>Jokin meni vikaan! Yritä uudelleen ja mikäli ongelma ei ratkea, ota yhteyttä ylläpitoon.</p>
            </div>
        </div >
    );
}

export default ErrorPage;